// @codekit-prepend quiet "libs/jquery.fancybox3.js"

// modal image popover with fancybox plugin

  $('[data-fancybox="gallery"]').fancybox({
    // Options will go here
    loop: true,
    openEffect: 'elastic',
    closeEffect: 'elastic',
    transitionEffect: "fade",
    transitionDuration: 2000,

    buttons: [
      "zoom",
      //"share",
      //"slideShow",
      //"fullScreen",
      //"download",
      "close"
    ],
  });

// Select all links with hashes
$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function (event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, "") ==
        this.pathname.replace(/^\//, "") &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $("html, body").animate(
          {
            scrollTop: target.offset().top,
          },
          1000,
          function () {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) {
              // Checking if the target was focused
              return false;
            } else {
              $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
              $target.focus(); // Set focus again
            }
          }
        );
      }
    }
  });


// browser window scroll (in pixels) after which the "back to top" link is shown
var offset = 300,
  //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
  offset_opacity = 1200,
  //duration of the top scrolling animation (in ms)
  scroll_top_duration = 700,
  //grab the "back to top" link
  $back_to_top = $('.cd-top');

//hide or show the "back to top" link
$(window).scroll(function(){
  ( $(this).scrollTop() > offset ) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
  if( $(this).scrollTop() > offset_opacity ) { 
    $back_to_top.addClass('cd-fade-out');
  }
});



// Animate images if in viewport

var $thumbnails = $('.image-container img')

addClassIfInViewPort($thumbnails, 'in-viewport')

function addClassIfInViewPort($els, classToAdd) {				
  $els.each(function () {
      if (isAnyPartOfElementInViewport(this) === true) {
          $(this).addClass(classToAdd);
      } else {
        $(this).removeClass(classToAdd);
      }
      
  })		
  return $els;
}	
  
function isAnyPartOfElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
    const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
    const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);
    return (vertInView && horInView);
}

$(window).scroll(function () {
    addClassIfInViewPort($thumbnails, 'in-viewport')
});


window.LMapTiles = function (map) {
  L.tileLayer(
    "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}##SCALE##".replace(
      "##SCALE##",
      L.Browser.retina ? "@2x.png" : ".png"
    ),
    {
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright" target="_blank" rel="noreferrer">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution" target="_blank" rel="noreferrer">CARTO</a>',
    }
  ).addTo(map);
};
window.LMapMarkerIcon = function (marker) {
  return L.divIcon({
    html: '<svg xmlns="http://www.w3.org/2000/svg" width="29" height="44" viewBox="0 0 145 220"><path fill="##FILL##" d="M72.5 0C32.558 0 0 32.6 0 72.578c0 27.381 19.615 48.841 33.668 70.04 14.053 21.197 22.871 42.608 30.453 70.937C65.177 217.508 68.986 220 72.5 220c3.514 0 7.323-2.492 8.379-6.445 8.275-30.92 17.037-52.17 30.902-72.715C125.647 120.294 145 99.89 145 72.578 145 32.6 112.442 0 72.5 0z"></path><text fill="#FFF" font-family="Arial" font-size="71" transform="translate(-1)" text-anchor="middle"><tspan x="71" y="95">##LABEL##</tspan></text></svg>'
      .replace("##FILL##", marker.color)
      .replace("##LABEL##", marker.label),
    iconSize: [29, 44],
    iconAnchor: [29 / 2, 44],
    className: "",
  });
};
/* Start Map: map */
const map = L.map("map", []).setView([mapLocation.lat, mapLocation.lng], 12);

window.LMapTiles(map);
map._markers = [];
[
  {
    position: {
      lat: mapLocation.lat,
      lng: mapLocation.lng,
    },
    label: "AE",
    color: "#b59626",
  },
].forEach(function (marker) {
  const m = L.marker(marker.position, { icon: window.LMapMarkerIcon(marker) });
  map._markers.push(m);
  map.addLayer(m);
});
/* End Map: map */
